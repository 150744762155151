<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
        <v-card class="overflow-hidden sticky">
          <l-widget>
            <template #header>
              <w-timer
                :start="widget.timer.start"
                :end="widget.timer.end"
                :server-time="widget.timer.current"
              />
            </template>

            <template #default>
              <w-progress
                :progress="widget.progress"
              />
            </template>
          </l-widget>
        </v-card>
      </div>

      <div class="col-12 col-xl-9 order-xl-1">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col-auto">
              <img
                class="w-100"
                :src="require(`@/assets/images/verstka/diagram-1.png`)"
                alt=""
              >
            </div>
          </div>

          <p class="my-32">
            Страница уже давно у нас. На самом деле, несколько тысячелетий.
            Первыми книгами были толстые глиняные плиты, созданные около
            4000 лет назад, которые вскоре были заменены свитками
            как предпочтительный способ употребления письменного слова.
            И хотя технология чтения прошла долгий путь - от папируса
          </p>

          <div class="row mx-n4 mb-32">
            <div
              v-for="answerItem in answers"
              :key="answerItem.text"
              class="col-12 col-lg px-4 mb-8 mb-lg-0"
            >
              <v-card-button
                :active="answer === answerItem.text"
                @click="answer = answerItem.text"
              >
                <template #selector>
                  <v-radio
                    :model-value="answer"
                    :value="answerItem.text"
                  ></v-radio>
                </template>

                <template #default>
                  <span v-html="answerItem.text "></span>
                </template>
              </v-card-button>
            </div>
          </div>

          <div class="row mb-32">
            <div class="col">
              Ответ: {{ answer }}
            </div>
          </div>

          <div class="row">
            <div class="col">
              <v-button @click="onClick">
                Ответ
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VButton,
  VCard,
  VCardButton,
  VRadio
} from '@components/base'
import { WTimer, WProgress } from '@components/widgets'
import { LWidget } from '@/layouts'

export default {
  name: 'SImgAndRadioCard',

  components: {
    LWidget,
    VButton,
    VCard,
    VCardButton,
    VRadio,
    WProgress,
    WTimer
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 120
        }
      },
      answer: '',
      answers: [
        { text: '<b>101 591 обращение</b>' },
        { text: '201 591 обращение' },
        { text: '301 591 обращение' },
        { text: '401 591 обращение' },
        { text: '501 591 обращение' }
      ]
    }
  },

  methods: {
    onClick () {
      console.log('clicked')
    }
  }
}
</script>
